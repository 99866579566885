import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { withRouter } from "react-router-dom";
import { withSnackbar } from "../SnackbarContainer"
import Flex from "../Flex"
import SearchFilters from "./SearchFilters"
import Pagination from "@material-ui/lab/Pagination"
import Box from "../Box";
import update from 'immutability-helper';
import InlineSearchContainer from '../search/InlineSearchContainer'
import Grid from "@material-ui/core/Grid";
import ListDisplay from "./ListDisplay"
import LinearProgress from '@material-ui/core/LinearProgress';
import QueryBuilderDialog from './QueryBuilderDialog';
import Button from '@material-ui/core/Button';

class IndexContainer extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getManuscripts = this.getManuscripts.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleSearchCloseClick = this.handleSearchCloseClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleCheckAllClick = this.handleCheckAllClick.bind(this)
    this.handleOpenQueryBuilder = this.handleOpenQueryBuilder.bind(this);
    this.handleCloseQueryBuilder = this.handleCloseQueryBuilder.bind(this);
    this.handleQuerySubmit = this.handleQuerySubmit.bind(this);
    this.handleRemoveQueryField = this.handleRemoveQueryField.bind(this);
    this.handleQueryReset = this.handleQueryReset.bind(this);
    this.handleQueryStringChange = this.handleQueryStringChange.bind(this)
    this.state = { manuscripts: this.props.manuscripts, manuscriptsCount: this.props.manuscripts_count, filterOptions: { companies: this.props.companies, products: this.props.products, journals: this.props.journals, years: this.props.years, targetSites: this.props.target_sites, therapyTypes: this.props.therapy_types, targetMarkets: this.props.target_markets, trials: this.props.trials }, filterValues: { company_ids: [], product_ids: [], journal_ids: [], begin_year: this.props.begin_year, end_year: this.props.end_year, target_site_ids: [], target_market_ids: [], therapy_type_ids: [], trial_ids: [], query: this.props.query, queryString: '', order_by: this.props.order_by, page: this.props.page, advancedQuery: [] }, showFilters: false, loading: false, rowsPerPage: 100, order: 'desc', queryBuilderOpen: false, createAlertOpen: false, estimatedAlerts: 0 }
  };

  componentDidMount() {
    const query = this.props.query == "*" ? null : this.props.query

    let params = Object.assign({}, this.state.filterValues)

    params['query'] = query

    this.setState({
      filterValues: params
    })

  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params['query'] = null
    params['order_by'] = 'published_date'
    params['advancedQuery'] = []

    this.getManuscripts(params)
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params['page'] = newPage

    this.getManuscripts(params)
  }

  handleChange(e) {

    const value = e.target.value

    let filterValues = update(this.state.filterValues, {
      query: { $set: value }
    })

    this.setState({
      filterValues: filterValues,
      search: false
    })
  }

  handleSearchCloseClick(e) {
    this.setState({
      search: false
    })
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)
    params['query'] = query
    params['order_by'] = '_score'

    this.getManuscripts(params)
  }

  handleFilterChange(value, filter) {
    let params = Object.assign({}, this.state.filterValues)

    if (filter == 'years') {
      params["begin_year"] = value[0]
      params["end_year"] = value[1]
    } else if (filter == 'order_by') {
      params["order_by"] = value
    } else if (params[filter].includes(value)) {
      params[filter] = params[filter].filter((item) => item != value)
    } else {
      params[filter].push(value)
    }

    this.getManuscripts(params)
  }

  handleCheckAllClick(filterOptions, filterName) {
    let params = Object.assign({}, this.state.filterValues)

    if (filterOptions.length == 0) {
      params[filterName] = []
    } else {
      params[filterName] = filterOptions.map((option) => option.name)
    }

    this.getManuscripts(params)
  }

  getManuscripts(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/publications`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {

        let filterOptions = Object.assign({}, this.state.filterOptions)

        filterOptions['journals'] = data.journals

        this.setState({
          manuscripts: data.manuscripts,
          manuscriptsCount: data.manuscripts_count,
          filterValues: params,
          loading: false,
          filterOptions: filterOptions
        })
      },
      error: (error) => {
        let errorMsg = error.responseJSON.error || 'An error occurred while processing your request.'
        this.props.snackbarShowMessage(errorMsg, 'error')
        this.setState({
          loading: false
        })
      }
    })
  }

  handleOpenQueryBuilder = () => {
    this.setState(prevState => ({
      queryBuilderOpen: true,
      filterValues: {
        ...prevState.filterValues,
        queryString: prevState.filterValues.query || ''
      }
    }));
  };

  handleCloseQueryBuilder = () => {
    this.setState({ queryBuilderOpen: false });
  };

  handleQueryReset = () => {
    this.setState(prevState => ({
      filterValues: {
        ...prevState.filterValues,
        query: null,
        order_by: 'published_date',
        advancedQuery: []
      }
    }), () => {
      this.getManuscripts(this.state.filterValues)
    })
  }

  handleQueryStringChange = (queryString) => {
    let params = Object.assign({}, this.state.filterValues);

    params['queryString'] = queryString

    this.setState({
      filterValues: params
    })
  }


  handleQuerySubmit = (fields, queryString) => {
    let params = Object.assign({}, this.state.filterValues);
    
    params['queryString'] = queryString
    params["query"] = queryString
    params['order_by'] = '_score';
    params['advancedQuery'] = fields;
    this.getManuscripts(params);
  };

  handleRemoveQueryField = (index) => {
    const newAdvancedQuery = [...this.state.advancedQuery];
    newAdvancedQuery.splice(index, 1);
    this.setState({ advancedQuery: newAdvancedQuery }, () => {
      this.handleQuerySubmit(newAdvancedQuery);
    });
  };

  render() {
    const numPages = Math.round(this.state.manuscriptsCount / 20)
    const pages = numPages == 0 ? 1 : numPages

    return(
      <Fragment>
        <Paper minFullHeight>

          <Box height="100%">
            <Box width={1/2} mx='auto'>
              <InlineSearchContainer
                searchLabel="Search publications" 
                query={this.state.filterValues.query || ""}
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
              />
              {this.props.user.user_company_id == 1 ? 
                <Flex>
                  <Box>
                    <Button 
                      onClick={this.handleOpenQueryBuilder} 
                      color="primary"
                      style={{ 
                        marginTop: '10px',
                        padding: '4px 8px',
                        fontSize: '0.8rem'
                      }}
                      size="small"
                    >
                      Advanced Search
                    </Button>
                  </Box>

                  <Box>
                  </Box>
                </Flex>
              : ""}
            </Box>

            <Grid container spacing={3} className='h-100'>
              <Grid item xs={4} sm={4} md={3} xl={2}>
                <SearchFilters
                  journals={this.state.filterOptions.journals}
                  trials={this.state.filterOptions.trials}
                  products={this.state.filterOptions.products}
                  years={this.state.filterOptions.years}
                  filterValues={this.state.filterValues}
                  handleFilterChange={this.handleFilterChange}
                />
              </Grid>

              <Grid item xs={12} sm={8} md={9} xl={10} className='h-100'>
                <Box height="100%">
                  {this.state.loading ?
                    <Box mt={1}>
                      <LinearProgress />
                    </Box>
                  : ""}
                  <ListDisplay 
                    manuscripts={this.state.manuscripts}
                    manuscriptsCount={this.state.manuscriptsCount}
                    page={this.state.filterValues.page}
                    handleChangePage={this.handleChangePage}
                    sector={this.props.sector}
                    handleRequestSort={this.handleRequestSort}
                  />
                </Box>
              </Grid>
            </Grid>
        
            <Flex mt={2} justifyContent='center'>
              <Pagination
                page={this.state.filterValues.page}
                count={pages}
                onChange={this.handleChangePage}
                showFirstButton
                showLastButton
              />
            </Flex>

          </Box>

          <QueryBuilderDialog
            open={this.state.queryBuilderOpen}
            onClose={this.handleCloseQueryBuilder}
            onSubmit={this.handleQuerySubmit}
            onReset={this.handleQueryReset}
            initialFields={this.state.filterValues.advancedQuery}
            currentQuery={this.state.filterValues.queryString}
            handleQueryStringChange={this.handleQueryStringChange}
          />

          

        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(withSnackbar(IndexContainer))