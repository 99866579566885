import React, { Component, Fragment } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Filters from "./Filters"
import AbstractsTable from "./AbstractsTable"
import update from "immutability-helper"
import FilterChipsContainer from "./FilterChipsContainer"
import Typography from "@material-ui/core/Typography"
import Button from "../buttons/Button"
import { withRouter } from "react-router-dom";
import Snackbar from "../Snackbar"

class Abstracts extends Component {
  constructor(props) {
    super(props)
    this.loadAbstracts = this.loadAbstracts.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleFilterExpandClick = this.handleFilterExpandClick.bind(this)
    this.getAbstracts = this.getAbstracts.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleSearchCloseClick = this.handleSearchCloseClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClearFilterClick = this.handleClearFilterClick.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.state = { activeTab: this.props.active_tab, showFilters: false, abstracts: this.props.abstracts, abstractsCount: this.props.abstracts_count, filterOptions: { companies: this.props.companies, trials: this.props.trials, products: this.props.products, meetings: this.props.meetings, targetSites: this.props.target_sites, targetMarkets: this.props.target_markets, therapyTypes: this.props.therapy_types, meetingYears: this.props.meeting_years }, filterValues: { company_ids: [], product_ids: [], trial_ids: [], years: [], meeting_ids: [], target_site_ids: [], target_market_ids: [], therapy_type_ids: [], query: null }, page: 0, chosen: { companies: this.props.chosen_companies, products: this.props.chosen_products, meetings: this.props.chosen_meetings, trials: this.props.chosen_trials, targetSites: this.props.chosen_target_sites, targetMarkets: this.props.chosen_target_markets, therapyTypes: this.props.chosen_therapy_types }, showSnackbar: false, snackbarText: '', search: false, orderBy: "meeting_name", order: "desc", loading: false }
  }

  componentDidMount() {
    let filterKeys = Object.keys(this.props.filter_values)
    let filterValueHash = { query: this.props.query }

    let parsedFilterValues = filterKeys.map((filterKey) =>
      this.props.filter_values[filterKey] == null ? filterValueHash[filterKey] = [] : filterValueHash[filterKey] = this.props.filter_values[filterKey].map((value) => filterKey == 'years' ? value : parseInt(value))
    )

    let filterValues = update(this.state.filterValues, {
      $set: filterValueHash
    })

    this.setState({
      filterValues: filterValues,
      search: this.props.query ? true : false
    })
  }

  handleFilterExpandClick(e) {
    const newShowFilterState = !this.state.showFilters

    this.setState({
      showFilters: newShowFilterState
    })
  }

  handleClearFilterClick(e) {
    let filterValues = update(this.state.filterValues, {
      product_ids: { $set: [] },
      trial_ids: { $set: [] },
      meeting_ids: { $set: [] },
      target_market_ids: { $set: [] },
      target_site_ids: { $set: [] },
      therapy_type_ids: { $set: [] },
      year_ids: { $set: [] },
      query: { $set: null }
    })

    this.setState({
      filterValues: filterValues
    }, () => {
      this.handleFilterChange('', '')
    })
  }

  handleChange(e) {

    const value = e.target.value

    let filterValues = update(this.state.filterValues, {
      query: { $set: value }
    })

    this.setState({
      filterValues: filterValues,
      search: false
    })
  }

  handleSearchCloseClick(e) {
    this.setState({
      search: false
    })
  }

  handleSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/abstracts`,
      data: {
        query: this.state.filterValues.query
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstracts: data.abstracts,
          abstractsCount: data.abstracts_count,
          search: true
        })
      }
    })
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["sort_by"] = this.state.orderBy
    params["order"] = this.state.order
    params["page"] = newPage

    this.loadAbstracts(params)
  }

  loadAbstracts(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/abstracts/sort_abstracts`,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {

        this.setState({
          abstracts: data.abstracts,
          page: params["page"],
          loading: false
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.',
          loading: false
        })
      },
      complete: (smthing) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    params["sort_by"] = this.state.orderBy
    params["order"] = this.state.order

    this.getAbstracts(params)
  }

  getAbstracts(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/abstracts`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        let filterOptions = { companies: data.companies, trials: data.trials, products: data.products, meetings: data.meetings, meetingYears: data.meeting_years, targetSites: data.target_sites, targetMarkets: data.target_markets, therapyTypes: data.therapy_types }

        let chosen = { companies: data.chosen_companies, products: data.chosen_products, meetings: data.chosen_meetings, trials: data.chosen_trials, targetSites: data.chosen_target_sites, targetMarkets: data.chosen_target_markets, therapyTypes: data.chosen_therapy_types }

        let filterKeys = Object.keys(params)

        let orderIndex = filterKeys.indexOf("order")
        let sortByIndex = filterKeys.indexOf("sort_by")

        filterKeys.splice(orderIndex,1)
        filterKeys.splice(sortByIndex,1)

        let queryParams = filterKeys.map((filterKey) => params[filterKey] && params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null).filter(param => param)

        let url = `/sectors/${this.props.sector.abbr}/abstracts`

        let flattenedQueryParams = [].concat.apply([], queryParams);
        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          abstracts: data.abstracts,
          abstractsCount: data.abstracts_count,
          filterValues: params,
          loading: false,
          filterOptions,
          chosen
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.',
          loading: false
        })
      }
    })
  }

  handleDelete(value, type) {
    if (type == 'query') {
      let filterValues = update(this.state.filterValues, {
        query: {
          $set: null
        }
      })

      this.getAbstracts(filterValues)
    } else {
      const index = this.state.filterValues[type].indexOf(value)

      const filterValues = update(this.state.filterValues, {
        [type]: { $splice: [[index, 1]] }
      })

      this.getAbstracts(filterValues)
    }
  }

  handleRequestSort(event, property) {
    let params = Object.assign({}, this.state.filterValues)

    params["sort_by"] = property

    let isDesc = this.state.orderBy === property && this.state.order === 'desc';

    params["order"] = isDesc ? 'asc' : 'desc'

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/abstracts/sort_abstracts`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          order: params["order"],
          orderBy: params["sort_by"],
          abstracts: data.abstracts,
          loading: false
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.',
          loading: false
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">

            <Box mb={2}>
              <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                forSearch={true}
                sector={this.props.sector}
                handleChange={this.handleChange}
                handleSearchClick={this.handleSearchClick}
                handleSearchCloseClick={this.handleSearchCloseClick}
                handleFilterChange={this.handleFilterChange}
              />
            </Box>

            <Flex alignItems='center'>
              <FilterChipsContainer
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                chosen={this.state.chosen}
                search={this.state.search}
                handleDelete={this.handleDelete}
              />

              <Box ml='auto'>
                <Flex alignItems='center'>

                  {Object.keys(this.state.filterValues).filter(filterValueKey => this.state.filterValues[filterValueKey] ? this.state.filterValues[filterValueKey].length > 0 : null).length > 0 ?
                    <Box mr={2}>
                      <Button onClick={this.handleClearFilterClick}>
                        Clear all
                      </Button>
                    </Box>
                  : ''}

                  <Box>
                    <Typography variant="body2">
                      {`${this.state.abstractsCount} abstracts`}
                    </Typography>
                  </Box>

                </Flex>
              </Box>
            </Flex>

            <AbstractsTable
              abstracts={this.state.abstracts}
              abstractsCount={this.state.abstractsCount}
              page={this.state.page}
              sector={this.props.sector}
              loading={this.state.loading}
              order={this.state.order}
              orderBy={this.state.orderBy}
              handleRequestSort={this.handleRequestSort}
              handleChangePage={this.handleChangePage}
            />

          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}


export default withRouter(Abstracts)
