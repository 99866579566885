import React, { useState } from "react"
import Form from "./Form"
import Tips from "./Tips"

export default function FormContainer(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    $.ajax({
      url: `/sectors/${props.sector.abbr}/search/tips_viewed`,
      type: "GET",
      success: function(data) {
        
      },
      error: function() {
        console.log("error");
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <React.Fragment>
      
      <Tips open={open} handleClose={handleClose} />

      <Form 
        query={props.query}
        placeholderText={props.search_text}
        handleSearchChange={props.handleSearchChange}
        handleSearchClick={props.handleSearchClick}
        handleTipsClick={handleClickOpen}
      />
    </React.Fragment>
  )
}