import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import { List, ListItem, ListItemText } from "../lists"
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from "@material-ui/core/Typography"
import Link from "../Link"

export default function ItemDisplay(props) {
  let highlightResult = props.patentParent.highlight ? props.patentParent.highlight : null

  return(
    <ListItem divider>
      <ListItemText>
        <Box pb={0.5}>
          <Typography variant="body1">
            <Link href={`/sectors/${props.sector.abbr}/patents/${props.patent.record_id || props.patent.id}`} textColor="mmi-blue">
              {highlightResult && highlightResult.title ?
                <span
                  dangerouslySetInnerHTML={{__html: highlightResult.title }}
                />
              : props.patent.title}
            </Link>
          </Typography>
        </Box>

        <Flex pb={0.5}>
          <Box mr={2}>
            <Typography variant="caption" color="textSecondary">
              {`${props.patent.country}${props.patent.xml_patent_no}${props.patent.patent_kind}`}
            </Typography>
          </Box>

          <Box mr={2}>
            <Typography variant="caption" color="textSecondary">
              {props.patent.kind ? capitalizeFirstLetter(props.patent.kind) : ""}
            </Typography>
          </Box>

          <Box mr={2}>
            <Typography variant="caption" color="textSecondary">
              {props.patent.date}
            </Typography>
          </Box>

        </Flex>

        <Box pb={0.5} width={1/2}>
          <Typography variant="body2">
            {highlightResult && highlightResult.abstract ?
              <span
                dangerouslySetInnerHTML={{__html: highlightResult.abstract }}
              />
            : props.patent.abstract ? truncateString(props.patent.abstract, 200) : ""}
          </Typography>
        </Box>

        <Box>
          <Typography variant="body2">
            <Link href={`/sectors/${props.sector.abbr}/companies/${props.patent.company_id}`} textColor="mmi-blue">
              {highlightResult && highlightResult.company_name ?
                <span
                  dangerouslySetInnerHTML={{__html: highlightResult.company_name }}
                />
              : props.patent.company_name}
            </Link>
          </Typography>
        </Box>
      </ListItemText>
    </ListItem>
  )
}
