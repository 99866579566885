import React from "react"
import Paper from "../Paper"
import Box from "../Box"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../tables"
import { List, ListItem, ListItemText, ListItemLink } from "../lists"

function InvestorPresentations(props) {
  return(
    <React.Fragment>
      <Paper fullHeight>
        <Box height="100%">
          <Box overflow="auto" height="90%">
            <List>
              {props.investor_presentations.map((investorPresentation) =>
                <ListItem button component={investorPresentation.url ? "a" : ""} divider href={investorPresentation.url ? investorPresentation.url : null} target="_blank">
                  <ListItemText primary={investorPresentation.title} secondary={humanDateFormat(investorPresentation.date)} />
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default InvestorPresentations
