import React from "react"
import CompanyItem from "./CompanyItem"
import ProductItem from "./ProductItem"
import DefaultResultItem from "./DefaultResultItem";
import AbstractItem from "./AbstractItem";
import TrialItem from "./TrialItem";
import PublicationItem from "./PublicationItem"
import NewsItem from "./NewsItem"
import FdaAdverseEventItem from "../fda_adverse_events/SearchItem"
import FdaPmaApprovalItem from "../fda_pma_approvals/SearchItem"
import FdaKClearanceItem from "../fda_k_clearances/SearchItem"
import DeviceApprovalItem from "../device_approvals/SearchItem"
import DeviceRecallItem from "../device_recalls/SearchItem"
import FdaHdeItem from "../fda_hdes/SearchItem"
import IctrpTrialItem from "../ictrp_trials/SearchItem"
import SecEdgarFilingItem from "../sec_edgar_filings/SearchItem"
import CtGovTrialItem from "../ct_gov_trials/SearchItem"

export default function ResultItem(props) {

  const componentMapping = {
    Company: CompanyItem,
    Product: ProductItem,
    Presentation: AbstractItem,
    Poster: AbstractItem,
    Trial: TrialItem,
    Publication: PublicationItem,
    'News Article': NewsItem,
    'Press Release': NewsItem,
    'FDA Adverse Event': FdaAdverseEventItem,
    'FDA PMA Approval': FdaPmaApprovalItem,
    '510k Clearance': FdaKClearanceItem,
    'FDA Human Device Exemption': FdaHdeItem,
    'ICTRP Trial': IctrpTrialItem,
    'SEC Edgar Filing': SecEdgarFilingItem,
    'CtGov Trial': CtGovTrialItem
  };

  const item = props.result
  const modelType = item.record_type;

  const isRecallType = modelType.includes("Recall");
  const shouldRenderDeviceApprovalItem = item.agency_acronym && (item.agency_acronym !== "PMA" && item.agency_acronym !== "510(k)" && item.agency_acronym !== "FDA HDE");

  let ComponentToRender;
  
  if (isRecallType) {
    ComponentToRender = DeviceRecallItem;
  } else if (shouldRenderDeviceApprovalItem) {
    ComponentToRender = DeviceApprovalItem;
  } else {
    ComponentToRender = componentMapping[modelType];
  }

  // Render the component, or a default if the model type is not mapped
  return ComponentToRender ? 
    <ComponentToRender result={item} sector={props.sector} admin={props.admin} query={props.query} /> : 
    <DefaultResultItem result={item} sector={props.sector} admin={props.admin} />;
}