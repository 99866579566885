import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Status from "./Status"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class StatusContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Switch>
          <Route
            path={`/sectors/${this.props.sector.abbr}/products/statuses`}
            children={<Status {...this.props} />}
          />
        </Switch>
      </Fragment>
    )
  }
}
