import React from "react"
import Flex from "../Flex"
import Box from "../Box"
import SearchIcon from '@material-ui/icons/Search'
import TextInput from "../TextInput"
import InputAdornment from '@material-ui/core/InputAdornment';
import RaisedButton from "../buttons/RaisedButton"
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

export default function Form(props) {
  return(
    <form onSubmit={props.handleSearchClick} acceptCharset="UTF-8">
      <Flex alignItems='center' width={1} justifyContent='center'>
        <Box width={1/2}>
          <TextInput
            name='query'
            label='Search'
            placeholder={props.placeholderText}
            value={props.query}
            onChange={props.handleSearchChange}
            className='w-100'
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
              endAdornment: <InputAdornment position="end"><IconButton onClick={props.handleTipsClick} color="default" size='small'><InfoIcon fontSize='small' /></IconButton></InputAdornment>
            }}
          />
        </Box>

        <Box ml={3}>
          <RaisedButton onClick={props.handleSearchClick}>
            Search
          </RaisedButton>
        </Box>

      </Flex>
    </form>
  )
}