import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { Table, TableBody, TableRow, TableHead, TableCell, TableHeadSort } from "../../tables"
import Link from "../../Link"
import AbstractTypePresentations from "./AbstractTypePresentations"
import Typography from "@material-ui/core/Typography"
import { Tabs, Tab } from "../../tabs"

export default class StagedExtractor extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { abstracts: this.props.abstracts, activeIndex: 'trials', order: 'desc', orderBy: 'priority_id' }
  }

  handleActiveIndexUpdate(e, newValue) {
    $.ajax({
      type: 'GET',
      url: `/admin/meetings/${this.props.meeting.id}/staged_extractor`,
      data: {
        entity_type: newValue
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstracts: data.abstracts,
          activeIndex: newValue
        })
      }
    })
    // this.setState({
    //   activeIndex: newValue
    // })
  }

  desc(a, b, orderBy) {
    let bOrderKey = orderBy == 'title' ? b[orderBy].toLowerCase() : b[orderBy]
    let aOrderKey = orderBy == 'title' ? a[orderBy].toLowerCase() : a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{ label: '#', name: 'number' }, { label: 'Title', name: 'title' }, { label: 'Priority', name: 'priority_id' }, { label: 'Media', title: 'pdf', align: 'right' } ]
    return(
      <Fragment>

        <Paper fullHeight>

          <Box mb={3}>
            <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
              <Tab label='Trials' value='trials' />
              <Tab label='Products' value='products' />
            </Tabs>
          </Box>

          <Flex justifyContent='right'>
            <Typography variant="body2">
              {`${Object.values(this.state.abstracts).length} Presentations`}
            </Typography>
          </Flex>

          <Box overflow='auto' maxHeight='80%' mt={3}>
            <Table size='small' aria-label='staged extractor table'>

              <TableHeadSort
                headers={headers}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
              />

              <TableBody>
                {this.stableSort(this.state.abstracts, this.getSorting(this.state.order, this.state.orderBy)).map((abstract) =>
                  <TableRow key={abstract.id} component={Link} href={this.state.activeIndex == 'products' ? `/admin/products/${abstract.product_id}/edit?source_id=${abstract.abstract_source_id}` : `/admin/abstracts/${abstract.id}/extractor`} target="_blank">

                    <TableCell>
                      {abstract.number}
                    </TableCell>

                    <TableCell>
                      {abstract.title}
                    </TableCell>

                    <TableCell>
                      {this.state.activeIndex == 'products' ? abstract.short_name : abstract.priority_id}
                    </TableCell>

                    <TableCell align='right'>
                      {abstract.pdf ? <span className='red'>YES</span> : ''}
                    </TableCell>

                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>

        </Paper>

      </Fragment>
    )
  }
}
