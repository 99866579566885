import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Select from "../Select"
import SourceItemDisplay from "./sources/SourceItemDisplay"
import List from "../lists/List"
import Box from "../Box"
import Flex from "../Flex"
import { Button, RaisedButton } from "../buttons"
import { FixedSizeList } from 'react-window';
import TextInput from "../TextInput"

export default class SourceForm extends Component {
  constructor(props) {
    super(props)
    this.handleSourceTypeChange = this.handleSourceTypeChange.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleQueryChange = this.handleQueryChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleMeetingChange = this.handleMeetingChange.bind(this)
    this.state = { sourceType: 'recently_used', sources: this.props.sources, query: '', filteredSources: null, meetings: '', science_meeting_occurrence_id: '' }
  }

  componentDidMount() {
    $.ajax({
      type: 'GET',
      url: '/admin/meetings/options',
      dataType: 'json',
      success: (data) => {
        this.setState({
          meetings: data.meeting_options,
          science_meeting_occurrence_id: data.meeting_options[0].value
        })
      }
    })
  }

  handleSearchClick(e) {
    $.ajax({
      url: '/admin/sources/search',
      data: {
        query: this.state.query, source_type: this.state.sourceType
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          sources: data.sources
        })
      }
    })
  }

  handleQueryChange(e) {
    let query = e.target.value
    this.setState({
      query: query
    })
  }

  handleSourceTypeChange(name, value) {
    const params = this.props.entity ? { source_type: value, entity_id: this.props.entity.id, entity_type: this.props.entityType } : { source_type: value }

    $.get('/admin/sources/filter', params, (data) => {
      this.setState({
        sourceType: data.source_type,
        sources: data.sources
      })
    }, 'json')
  }

  handleMeetingChange(name, value) {
    const params = this.props.entity ? { source_type: this.state.sourceType, entity_id: this.props.entity.id, entity_type: this.props.entityType, science_meeting_occurrence_id: this.state.science_meeting_occurrence_id } : { science_meeting_occurrence_id: value }

    $.get('/admin/sources/filter', params, (data) => {
      this.setState({
        sources: data.sources,
        science_meeting_occurrence_id: value
      })
    }, 'json')
  }

  handleSourceChange(entity) {
    this.props.handleSourceChange(entity, this.state.sourceType)
  }

  render () {

    return (
      <React.Fragment>

        <Box display="flex" alignItems='end'>
          <Box>
            <Select
              name='source_type'
              value={this.state.sourceType}
              label='Source Type'
              entities={this.props.sourceTypes}
              handleChange={this.handleSourceTypeChange}
            />
          </Box>

          {this.state.sourceType == 'abstract' ?
            <Box>
              <Select
                name='science_meeting_occurrence_id'
                value={this.state.science_meeting_occurrence_id}
                label='Meeting'
                entities={this.state.meetings}
                handleChange={this.handleMeetingChange}
              />
            </Box>
          : ''}

          {this.state.sourceType != 'recently_used' ?
            <Box ml={3}>
              <Button tag='a' href={`/admin/${this.state.sourceType}s/new?r=${this.props.redirectUrl}`}>
                Create New Source
              </Button>
            </Box>
          : ''}
        </Box>

        <Flex alignItems='center' my={2}>
          <Box width={1/2}>
            <TextInput
              name='search'
              label='Search'
              value={this.state.query}
              onChange={this.handleQueryChange}
              className='w-100'
            />
          </Box>

          <Box ml={3}>
            <RaisedButton onClick={this.handleSearchClick}>
              Search
            </RaisedButton>
          </Box>
        </Flex>

        <Box mt={2}>
          {this.state.sources ? <FixedSizeList height={600} itemSize={46} overscanCount={3} itemCount={(this.state.filteredSources || this.state.sources) ? (this.state.filteredSources ? this.state.filteredSources.length : this.state.sources.length) : 0} itemData={{sources: (this.state.filteredSources || this.state.sources), handleClick: this.handleSourceChange, activeSource: this.props.source}}>

            {SourceItemDisplay}

          </FixedSizeList> : <div>Please choose a source type from above</div>}

        </Box>

      </React.Fragment>
    );
  }
}
